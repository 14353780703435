import * as React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import {
  Row, Col, Button, Divider, Alert, Tooltip, notification
} from 'antd';
import {
  SubmissionStep,
  SUBMISSION_INITIALSING,
  SUBMISSION_SUBMITTING,
  SUBMISSION_SUBMITTED,
  SUBMISSION_UNEXPECTED_ERROR,
  SUBMISSION_STEP
} from 'componentlibrary';
import { getCurrentLanguage } from '../../translations/languageOptions';

export class BatchCompanySubmissionStep extends React.Component {
  static freshState() {
    return {
      submissionState: SUBMISSION_INITIALSING,
      submissionDetail: {}
    };
  }

  constructor() {
    super();
    this.state = BatchCompanySubmissionStep.freshState();

    this.submissionResultView = this.submissionResultView.bind(this);
    this.downloadLogFile = this.downloadLogFile.bind(this);
    this.logDownloadButton = this.logDownloadButton.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { batchStep } = this.props;
    const prevBatchStep = prevProps.batchStep;

    if (batchStep !== SUBMISSION_STEP) {
      return;
    }

    if (batchStep !== prevBatchStep) {
      this.submitPayload();
    }
  }

  async downloadLogFile(transactionId, status) {
    const { t, i18n, getXlsxLogForBatchTransaction } = this.props;
    const currentSelectedLocale = getCurrentLanguage(i18n.languages);

    notification.destroy();
    try {
      const response = await getXlsxLogForBatchTransaction(
        transactionId,
        currentSelectedLocale.key
      );

      const fileUrl = status === 'success' ? response.downloadUrlForSuccessLog : response.downloadUrlForFailLog;
      window.location.replace(fileUrl);
    } catch (e) {
      notification.error({
        message: t('common:error'),
        description: t('common:unexpectedErrorMessage'),
        duration: 30
      });
    }
  }

  logDownloadButton(transactionId, status) {
    const { t } = this.props;
    return (
      <Tooltip placement="topLeft" title={t('logDownloadTooltip')}>
        <Button
          icon="download"
          onClick={() => this.downloadLogFile(transactionId, status)}
          className="log-download"
        >
          {t('logButton')}
        </Button>
      </Tooltip>
    );
  }

  submissionResultView() {
    const { t } = this.props;
    const { submissionState, submissionDetail } = this.state;

    if (![SUBMISSION_SUBMITTED, SUBMISSION_UNEXPECTED_ERROR].includes(submissionState)) {
      return null;
    }

    const unexpectedError = SUBMISSION_UNEXPECTED_ERROR === submissionState;

    let successRecordCount = null;
    let failRecordCount = null;
    let transactionId = null;

    if (submissionDetail) {
      successRecordCount = submissionDetail.success;
      failRecordCount = submissionDetail.fail;
      transactionId = submissionDetail.transactionId;
    }

    return (
      <>
        <Divider style={{ margin: '10px 0 10px' }} />
        <Row gutter={[16, 16]}>
          {unexpectedError && (
            <Col xs={24} md={24}>
              <Alert
                message={t('common:error')}
                description={t('batchProcess:unexpectedErrorSubmitting')}
                type="error"
                showIcon
              />
            </Col>
          )}
          {(successRecordCount && successRecordCount > 0) ? (
            <Col xs={24} md={12}>
              <Alert
                message={t('batchProcess:submissionSuccessfulMessage')}
                description={(
                  <>
                    <p>
                      {t('batchProcess:submissionSuccessfulDescription', { record_count: successRecordCount })}
                    </p>
                    {this.logDownloadButton(transactionId, 'success')}
                  </>
                )}
                type="success"
                showIcon
              />
            </Col>
          ) : null}
          {(failRecordCount && failRecordCount > 0) ? (
            <Col xs={24} md={12}>
              <Alert
                message={t('batchProcess:submissionFailedMessage')}
                description={(
                  <>
                    <p>{t('batchProcess:submissionFailedDescription', { record_count: failRecordCount })}</p>
                    {this.logDownloadButton(transactionId, 'fail')}
                  </>
                )}
                type="error"
                showIcon
              />
            </Col>
          ) : null}
        </Row>
      </>
    );
  }

  async submitPayload() {
    const { batchStep, batchState, submitBatchPayload } = this.props;

    if (!batchState
      || batchStep !== SUBMISSION_STEP
      || !batchState.uploadRequestResponse
      || !batchState.uploadRequestResponse.payloadForSubmission) {
      return;
    }

    try {
      this.setState({
        submissionState: SUBMISSION_SUBMITTING,
        submissionDetail: {}
      });

      const response = await submitBatchPayload({
        batch: batchState.uploadRequestResponse.payloadForSubmission,
        returnDetailedResponse: false
      });

      this.setState({
        submissionState: SUBMISSION_SUBMITTED,
        submissionDetail: {
          fail: response.stats.fail,
          success: response.stats.success,
          transactionId: response.transactionUuid
        }
      });
    } catch (error) {
      this.setState({
        submissionState: SUBMISSION_UNEXPECTED_ERROR,
        submissionDetail: {}
      });
    }
  }

  render() {
    const { batchStep, batchState, handleStepChange } = this.props;
    const { submissionState, submissionDetail } = this.state;

    return (
      <SubmissionStep
        batchStep={batchStep}
        batchState={batchState}
        handleStepChange={handleStepChange}
        submissionState={submissionState}
        submissionDetail={submissionDetail}
        submissionResultViewOverride={this.submissionResultView}
      />
    );
  }
}

BatchCompanySubmissionStep.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  batchStep: PropTypes.any.isRequired,
  batchState: PropTypes.object.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  submitBatchPayload: PropTypes.func.isRequired,
  getXlsxLogForBatchTransaction: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchCompanySubmissionStep);
