const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://hnkax4egug.execute-api.us-west-2.amazonaws.com/prod',
    COMPANY_API_URL: 'https://ekm9zgb136.execute-api.us-west-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: '1',
    HOSTNAME: 'https://taxonomy-company.dev.gromit.forwoodsafety.com/'
  }
};

export default config;
