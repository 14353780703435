import { connect } from 'react-redux';

import CompanyExporter from '../../Components/CompanyExporter';
import { exportCompanyListToExcel } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapDispatchToProps = (dispatch) => ({
  exportCompanyListToExcel(...args) {
    return exportCompanyListToExcel(dispatch, ...args);
  }
});

export default connect(mapDispatchToProps)(WithDefaults(CompanyExporter));
