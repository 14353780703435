import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { Row, Col, Spin } from 'antd';
import equal from 'deep-equal';
import { ResponsiveTable, Empty } from 'componentlibrary';

import Base from '../Base';
import PhysicalLocations from '../../Containers/PhysicalLocations';
import { defaultSort } from '../../reducers/company';

export class CompanyList extends Base {
  static booleanIcon(value) {
    if (!value) {
      return (
        <span>&nbsp;</span>
      );
    }

    return (
      <FontAwesomeIcon
        icon={faCheckCircle}
        className="md-icon"
        color="green"
      />
    );
  }

  static nameWithPhysicalLocationTrigger({ name, uuid }) {
    return (
      <>
        <span>{name}</span>
        <PhysicalLocations companyUuid={uuid} />
      </>
    );
  }

  static transformDataForDisplay(data) {
    return data.map((_item) => {
      const item = { ..._item };

      item.name = CompanyList.nameWithPhysicalLocationTrigger(item);
      item.corporateCompany = CompanyList.booleanIcon(item.corporateCompany);
      item.operational = CompanyList.booleanIcon(item.operational);

      return item;
    });
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      sort: this.sortMeta,
      hasFetched: false
    };

    this.changeSort = this.changeSort.bind(this);
    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    if (!fetchData) {
      return;
    }

    this.fetchList();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (!equal(prevProps.filter, filter)) {
      this.fetchList();
    }
  }

  get sortMeta() {
    const { list, onSortingChange } = this.props;
    const { meta = {} } = list;
    const { sorting } = meta;

    const currentConfig = sorting || defaultSort;
    if (onSortingChange) {
      onSortingChange(currentConfig);
    }
    return currentConfig;
  }

  get tableHeader() {
    const { t } = this.props;
    const { sort } = this.state;

    const headerItem = (key, label, width) => ({
      key,
      label,
      sortFn: () => this.changeSort(key),
      isCurrentSortField: sort.key === key,
      isReverse: sort.reverse === true,
      width
    });

    return [
      headerItem('name', t('name'), '36%'),
      headerItem('meta.parentName', t('parent'), '36%'),
      headerItem('operational', t('operational'), '8%'),
      headerItem('corporateCompany', t('corporate'), '8%'),
      headerItem('status', t('status'), '12%')
    ];
  }

  get resultCount() {
    const { list } = this.props;
    const { meta = {} } = list;
    const { pagination = {} } = meta;
    const { resultCount = 0 } = pagination;

    return resultCount;
  }

  async fetchList() {
    const { t, getCompanyList, filter } = this.props;
    const { sort } = this.state;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getCompanyList, filter, sort);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  changeSort(key) {
    const { onSortingChange } = this.props;
    const { sort } = this.state;
    let reverse = false;

    if (key === sort.key) {
      reverse = !sort.reverse;
    }

    const newSortKey = {
      key,
      reverse
    };

    this.setState({
      sort: newSortKey
    }, this.fetchList);

    if (onSortingChange) {
      onSortingChange(newSortKey);
    }
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { t, list } = this.props;
    const { loading } = this.state;
    const { data } = list;
    const { resultCount } = this;

    return (
      <Spin
        spinning={loading}
        size="large"
        style={{ marginTop: 40 }}
      >
        <Row style={{ marginBottom: 20 }}>
          <Col>
            {resultCount > 0 && t('resultCount', {
              resultCount,
              item: resultCount > 1 ? t('companies').toLowerCase() : t('company').toLowerCase()
            })}
          </Col>
        </Row>
        <ResponsiveTable
          header={this.tableHeader}
          data={CompanyList.transformDataForDisplay(data)}
        />
        {
          !loading && data.length === 0 && (
            <Empty message={t('common:noRecordsFound')} />
          )
        }
      </Spin>
    );
  }
}

CompanyList.defaultProps = {
  fetchData: true,
  filter: {}
};

CompanyList.propTypes = {
  t: PropTypes.func.isRequired,
  list: PropTypes.object.isRequired,
  getCompanyList: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  fetchData: PropTypes.bool,
  filter: PropTypes.object,
  onSortingChange: PropTypes.func
};

export default withTranslation(['companies', 'common'])(CompanyList);
