import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Media from 'react-media';
import {
  Col, Row, Divider, Radio, Icon, Button, Dropdown, Menu
} from 'antd';
import { Link } from 'react-router-dom';

import CompanyList from '../../Containers/CompanyList';
import CompanyStructure from '../../Containers/CompanyStructure';
import CompanyFilter from '../CompanyFilter';
import CompanyExporter from '../../Containers/CompanyExporter';

export class Companies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'list',
      filter: {},
      sort: {},
      hasList: false,
      hasStructure: false
    };

    this.onChange = this.onChange.bind(this);
    this.onFilter = this.onFilter.bind(this);
    this.onSortingChange = this.onSortingChange.bind(this);
  }

  componentWillUnmount() {
    const { clearCompanyData } = this.props;
    clearCompanyData();
  }

  onChange(e) {
    this.setState({
      view: e.target.value
    });
  }

  onFilter(filter) {
    this.setState({
      filter,
      hasList: false,
      hasStructure: false
    });
  }

  onSortingChange(sort) {
    this.setState({ sort });
  }

  get hasData() {
    const { hasList, hasStructure } = this.state;
    return hasList || hasStructure;
  }

  renderView() {
    const {
      view, filter, hasList, hasStructure
    } = this.state;

    if (view === 'list') {
      return (
        <div className="company-list">
          <CompanyList
            onDataRetrieved={() => this.setState({ hasList: true })}
            filter={filter}
            fetchData={!hasList}
            onSortingChange={this.onSortingChange}
          />
        </div>
      );
    }

    return (
      <div className="company-structure">
        <CompanyStructure
          onDataRetrieved={() => this.setState({ hasStructure: true })}
          filter={filter}
          fetchData={!hasStructure}
        />
      </div>
    );
  }

  renderButtons(isMobile) {
    const { t } = this.props;
    const { filter, sort } = this.state;
    return (
      <Button.Group
        style={isMobile ? { marginTop: 30 } : { float: 'right' }}
      >
        <CompanyExporter
          filter={filter}
          sort={sort}
          text={t('export')}
          style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
        />
        <Dropdown
          trigger={['click']}
          overlay={this.renderBatchProcessMenu()}
        >
          <Button
            icon="retweet"
            style={isMobile ? { width: '100%', marginBottom: 15 } : {}}
          >
            {t('batchProcess')}
          </Button>
        </Dropdown>
      </Button.Group>
    );
  }

  renderBatchProcessMenu() {
    const { t } = this.props;
    return (
      <Menu>
        <Menu.Item key="new-batch-transaction">
          <Link to="/company/batch-process">
            <Icon type="upload" />
            {t('newBatchProcess')}
          </Link>
        </Menu.Item>
        {/* <Menu.Item key="view-batch-transaction">
          <Link to="/company/batch-transaction">
            <Icon type="bars" /> {t('transactions')}
          </Link>
        </Menu.Item> */}
      </Menu>
    );
  }

  render() {
    const { t } = this.props;
    const { view, filter } = this.state;

    return (
      <Media query="(max-width: 769px)">
        { (isMobile) => (
          <>
            <Row type="flex" justify="center" align="top">
              <Col span={12}>
                <h1><strong>{t('companies')}</strong></h1>
                <Divider />
              </Col>
              <Col span={12} className="text-right">
                <Radio.Group
                  value={view}
                  buttonStyle="solid"
                  onChange={this.onChange}
                  disabled={!this.hasData}
                  style={{ position: 'relative', top: -4 }}
                >
                  <Radio.Button value="list"><Icon type="bars" /></Radio.Button>
                  <Radio.Button value="tree"><Icon type="apartment" /></Radio.Button>
                </Radio.Group>
                <Divider style={{ marginTop: 16 }} />
              </Col>
            </Row>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <CompanyFilter
                  onFilter={this.onFilter}
                  currentFilter={filter}
                  renderButtons={() => (this.renderButtons(isMobile))}
                />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                { this.renderView() }
              </Col>
            </Row>
          </>
        )}
      </Media>
    );
  }
}

Companies.propTypes = {
  t: PropTypes.func.isRequired,
  clearCompanyData: PropTypes.func.isRequired
};

export default withTranslation(['companies', 'common'])(Companies);
