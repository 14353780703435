import { connect } from 'react-redux';

import Companies from '../../Components/Companies';
import { clearCompanyData } from '../../reducers/company';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  clearCompanyData(...args) {
    return clearCompanyData(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Companies);
