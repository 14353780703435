import { connect } from 'react-redux';

import BatchCompany from '../../Components/BatchCompany';
import { batchXlsxProcessor, batchCompany, getXlsxLogForBatchTransaction } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  batchXlsxProcessor(...args) {
    return batchXlsxProcessor(dispatch, ...args);
  },
  batchCompany(...args) {
    return batchCompany(dispatch, ...args);
  },
  getXlsxLogForBatchTransaction(...args) {
    return getXlsxLogForBatchTransaction(dispatch, ...args);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(BatchCompany));
