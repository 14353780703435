import React from 'react';
import PropTypes from 'prop-types';
import equal from 'deep-equal';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';
import { Empty } from 'componentlibrary';
import {
  Row, Col, Tree, Spin, Card
} from 'antd';

import Base from '../Base';
import PhysicalLocations from '../../Containers/PhysicalLocations';
import { inlineLoader } from '../../sass/modules/loader.module.scss';
import styles from './styles.module.scss';

export class CompanyStructure extends Base {
  static nameWithPhysicalLocationTrigger({ name, uuid, status }) {
    return (
      <>
        <span>{Base.nameWithStatus(name, status)}</span>
        <PhysicalLocations companyUuid={uuid} />
      </>
    );
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasFetched: false
    };

    this.loading = this.loading.bind(this);
  }

  componentDidMount() {
    const { fetchData } = this.props;
    if (!fetchData) {
      return;
    }

    this.fetchStructure();
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    if (!equal(prevProps.filter, filter)) {
      this.fetchStructure();
    }
  }

  get isEmptyStructure() {
    const { structure } = this.props;
    const { data } = structure;

    return Object.keys(data).length === 0;
  }

  get hasFilter() {
    const { filter } = this.props;

    return Object.keys(filter).length > 0;
  }

  get resultCount() {
    const { structure } = this.props;
    const { meta = {} } = structure;
    const { resultCount = 0 } = meta;

    return resultCount;
  }

  async fetchStructure() {
    const { t, getCompanyStructure, filter } = this.props;

    try {
      this.loading(true);
      await super.dispatchWithAuth(getCompanyStructure, filter);
      super.onDataRetrieved();
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
    }
  }

  matchedWithFilter(company) {
    const { loading } = this.state;
    return !loading && this.hasFilter && company.matched;
  }

  unmatchedWithFilter(company) {
    const { loading } = this.state;
    return !loading && this.hasFilter && !company.matched;
  }

  treeNode(children) {
    if (!children) {
      return null;
    }

    const { TreeNode } = Tree;

    return children.map((company) => (
      <TreeNode
        selectable={false}
        title={CompanyStructure.nameWithPhysicalLocationTrigger(company)}
        key={company.uuid}
        className={classnames({
          [styles.unmatched]: this.unmatchedWithFilter(company),
          [styles.matched]: this.matchedWithFilter(company)
        })}
      >
        {this.treeNode(company.children)}
      </TreeNode>
    ));
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { t, structure } = this.props;
    const { loading } = this.state;
    const { data } = structure;
    const { resultCount } = this;

    if (!loading && this.isEmptyStructure) {
      return (
        <Empty message={t('common.noRecordsFound')} />
      );
    }

    return (
      <Spin
        spinning={loading}
        size="large"
        style={{ marginTop: 20 }}
        className={inlineLoader}
      >
        {
          resultCount > 0 && (
            <Row style={{ marginBottom: 20 }}>
              <Col>
                {t('resultCount', {
                  resultCount,
                  item: resultCount > 1 ? t('companies').toLowerCase() : t('company').toLowerCase()
                })}
              </Col>
            </Row>
          )
        }
        {
          !this.isEmptyStructure && (
            <Card>
              <Tree defaultExpandAll>
                {this.treeNode([data])}
              </Tree>
            </Card>
          )
        }
      </Spin>
    );
  }
}

CompanyStructure.defaultProps = {
  fetchData: true,
  filter: {}
};

CompanyStructure.propTypes = {
  t: PropTypes.func.isRequired,
  structure: PropTypes.object.isRequired,
  getCompanyStructure: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  fetchData: PropTypes.bool,
  filter: PropTypes.object
};

export default withTranslation(['companies'])(CompanyStructure);
