import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button } from 'antd';

import Base from '../Base';

function download(url) {
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export class CompanyExporter extends Base {
  constructor(props) {
    super(props);

    this.state = {};
    this.exportToExcel = this.exportToExcel.bind(this);
    this.download = download.bind(this);
  }

  async exportToExcel() {
    const {
      t, exportCompanyListToExcel, filter, sort
    } = this.props;

    try {
      this.loading(true);
      this.clearNotification();
      this.showMessage(t('exportCompanyInfo'));

      const response = await super.dispatchWithAuth(exportCompanyListToExcel, filter, sort);
      this.download(response.downloadUrl);
    } catch (err) {
      // Unauthorized errors are already handled in the reducer
      if (err.status !== 401) {
        super.handleError(t('fetchError'));
      }
    } finally {
      this.loading(false);
      this.hideMessage(false);
    }
  }

  loading(state) {
    this.setState({ loading: state });
  }

  render() {
    const { style, disabled, text } = this.props;
    const { loading } = this.state;

    return (
      <>
        <Button
          icon="download"
          loading={loading}
          onClick={this.exportToExcel}
          style={style}
          disabled={disabled}
        >
          {text}
        </Button>
      </>
    );
  }
}

CompanyExporter.defaultProps = {
  filter: {},
  sort: {}
};

CompanyExporter.propTypes = {
  t: PropTypes.func.isRequired,
  exportCompanyListToExcel: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired,
  filter: PropTypes.object,
  sort: PropTypes.object
};

export default withTranslation(['companies', 'common'])(CompanyExporter);
