import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { BatchProcess, UploadStep } from 'componentlibrary';
import Base from '../Base';
import BatchCompanyValidationStep from './BatchCompanyValidationStep';
import BatchCompanySubmissionStep from './BatchCompanySubmissionStep';

import { getCurrentLanguage } from '../../translations/languageOptions';

export class BatchCompany extends Base {
  constructor(props) {
    super(props);
    this.uploadValidateAndConvert = this.uploadValidateAndConvert.bind(this);
    this.submitBatchPayload = this.submitBatchPayload.bind(this);
    this.getXlsxLogForBatchTransaction = this.getXlsxLogForBatchTransaction.bind(this);
  }

  uploadValidateAndConvert(file) {
    const { i18n, batchXlsxProcessor } = this.props;
    const currentSelectedLanguage = getCurrentLanguage(i18n.languages);
    const localeValue = currentSelectedLanguage.key;
    return this.dispatchWithAuth(batchXlsxProcessor, localeValue, file);
  }

  submitBatchPayload(payload) {
    const { batchCompany } = this.props;
    return this.dispatchWithAuth(batchCompany, payload);
  }

  getXlsxLogForBatchTransaction(transactionId, locale) {
    const { getXlsxLogForBatchTransaction } = this.props;
    return this.dispatchWithAuth(getXlsxLogForBatchTransaction, transactionId, locale);
  }

  render() {
    return (
      <BatchProcess>
        <UploadStep
          uploadFileHandler={this.uploadValidateAndConvert}
        />
        <BatchCompanyValidationStep />
        <BatchCompanySubmissionStep
          submitBatchPayload={this.submitBatchPayload}
          getXlsxLogForBatchTransaction={this.getXlsxLogForBatchTransaction}
        />
      </BatchProcess>
    );
  }
}

BatchCompany.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.any.isRequired,
  batchXlsxProcessor: PropTypes.func.isRequired,
  batchCompany: PropTypes.func.isRequired,
  getXlsxLogForBatchTransaction: PropTypes.func.isRequired,
  getIdToken: PropTypes.func.isRequired
};

export default withTranslation(['batchProcess'])(BatchCompany);
