import { connect } from 'react-redux';

import CompanyStructure from '../../Components/CompanyStructure';
import { getCompanyStructure } from '../../reducers/company';
import WithDefaults from '../WithDefaults';

const mapStateToProps = (state, ownProps) => ({
  structure: state.company.structure,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  getCompanyStructure(...args) {
    return getCompanyStructure(dispatch, ...args);
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(WithDefaults(CompanyStructure));
