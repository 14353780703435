import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Media from 'react-media';
import {
  Col, Row, Input, Select, Card
} from 'antd';

export class CompanyFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchBy: 'name'
    };

    this.searchInput = React.createRef();
  }

  applyFilter(key, value) {
    const { onFilter, currentFilter } = this.props;

    let filter = Object.assign(currentFilter, {});

    if (!value) {
      filter = {};
    } else {
      filter = {
        ...filter,
        [`filter.${key}`]: value
      };
    }

    onFilter(filter);
  }

  render() {
    const { t, renderButtons } = this.props;
    const { searchBy } = this.state;
    const searchKeys = ['name'];

    return (
      <Media query="(max-width: 769px)">
        {(isMobile) => (
          <Card>
            <Row>
              <h3>{t('searchBoxTitle')}</h3>
              <Col md={12} xs={24} className="search">
                <Input.Group compact>
                  <Select
                    defaultValue="name"
                    dropdownMatchSelectWidth={false}
                    onChange={(value) => this.setState({ searchBy: value })}
                    value={searchBy}
                    style={{ width: isMobile ? '100%' : 145 }}
                  >
                    {
                      searchKeys.map((key) => (
                        <Select.Option key={key} value={key}>{t(key)}</Select.Option>
                      ))
                    }
                  </Select>
                  <Input.Search
                    allowClear
                    id="search-input"
                    ref={this.searchInput}
                    onSearch={(value) => this.applyFilter(searchBy, value)}
                    defaultValue=""
                    style={{ width: isMobile ? '100%' : 370 }}
                    placeholder={t(`searchPlaceHolder.${searchBy}`)}
                  />
                </Input.Group>
              </Col>
              <Col md={12} xs={24}>
                {renderButtons && renderButtons()}
              </Col>
            </Row>
          </Card>
        )}
      </Media>
    );
  }
}

CompanyFilter.defaultProps = {
  currentFilter: {},
  renderButtons: null
};

CompanyFilter.propTypes = {
  t: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  currentFilter: PropTypes.object,
  renderButtons: PropTypes.func
};

export default withTranslation(['companies'])(CompanyFilter);
